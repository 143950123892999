import * as Ariakit from "@ariakit/react"
import { clsx } from "clsx"
import * as React from "react"

import formStyles from "./form.module.css"

export interface SelectProps extends Ariakit.SelectProps {
  value?: string
  setValue?: (value: string) => void
  defaultValue?: string
  onBlur?: React.FocusEventHandler<HTMLElement>
}

export const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  function Select({ children, value, setValue, defaultValue, ...props }, ref) {
    const select = Ariakit.useSelectStore({ value, setValue, defaultValue })
    const portalRef = React.useRef<HTMLDivElement>(null)
    const selectValue = select.useState("value")

    // Only call onBlur if the focus is leaving the whole widget.
    const onBlur = (event: React.FocusEvent<HTMLElement>) => {
      const portal = portalRef.current
      const { selectElement, popoverElement } = select.getState()
      if (portal?.contains(event.relatedTarget)) return
      if (selectElement?.contains(event.relatedTarget)) return
      if (popoverElement?.contains(event.relatedTarget)) return
      props.onBlur?.(event)
    }

    return (
      <>
        <Ariakit.Select
          ref={ref}
          {...props}
          store={select}
          onBlur={onBlur}
          className={clsx(formStyles["select"], props.className)}
        >
          {selectValue || "Select an item"}
          <Ariakit.SelectArrow />
        </Ariakit.Select>
        <Ariakit.SelectPopover
          store={select}
          modal
          sameWidth
          onBlur={onBlur}
          portalRef={portalRef}
          className={formStyles["selectPopover"]}
        >
          {children}
        </Ariakit.SelectPopover>
      </>
    )
  }
)

export type SelectItemProps = Ariakit.SelectItemProps

export const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  function SelectItem(props, ref) {
    return (
      <Ariakit.SelectItem
        ref={ref}
        {...props}
        className={clsx(formStyles["selectPopoverItem"], props.className)}
      />
    )
  }
)
