import * as Ariakit from "@ariakit/react"
import { FormControl } from "@ariakit/react"
import { clsx } from "clsx"
import * as React from "react"

import formStyles from "./form.module.css"
import { Select } from "./select.jsx"
import type { SelectProps } from "./select.jsx"

export type FormProps = Ariakit.FormProps

export const Form = React.forwardRef<HTMLFormElement, FormProps>(function Form(
  props,
  ref
) {
  return (
    <Ariakit.Form
      ref={ref}
      {...props}
      className={clsx("wrapper", props.className)}
    />
  )
})

export interface FormFieldProps extends React.ComponentPropsWithoutRef<"div"> {
  name: Ariakit.FormControlProps["name"]
  label: React.ReactNode
  explanation?: React.ReactNode
}

export const FormField = React.forwardRef<HTMLDivElement, FormFieldProps>(
  function FormField({ name, label, explanation, children, ...props }, ref) {
    return (
      <div
        ref={ref}
        {...props}
        className={clsx(formStyles["wrapper"], props.className)}
      >
        <Ariakit.FormLabel name={name} className={formStyles["label"]}>
          {label}
        </Ariakit.FormLabel>
        {explanation && (
          <p className={formStyles["explanation"]}>{explanation}</p>
        )}
        {children}
        <Ariakit.FormError name={name} className={formStyles["error"]} />
      </div>
    )
  }
)

export type FormInputProps = Ariakit.FormInputProps

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  function FormInput(props, ref) {
    return (
      <Ariakit.FormInput
        ref={ref}
        {...props}
        className={clsx(formStyles["input"], props.className)}
      />
    )
  }
)

export type FormSubmitProps = Ariakit.FormSubmitProps

export const FormSubmit = React.forwardRef<HTMLButtonElement, FormSubmitProps>(
  function FormSubmit(props, ref) {
    return (
      <Ariakit.FormSubmit
        ref={ref}
        {...props}
        className={clsx("button primary", props.className)}
      />
    )
  }
)

export interface FormSelectProps
  extends Ariakit.FormControlProps<"button">,
    Omit<SelectProps, keyof Ariakit.FormControlProps<"button">> {
  label?: string
}

export const FormSelect = React.forwardRef<HTMLButtonElement, FormSelectProps>(
  function FormSelect({ name, label, ...props }, ref) {
    const form = Ariakit.useFormContext()
    if (!form) throw new Error("FormSelect must be used within a Form")

    const value = label || form.useValue(name)

    const select = (
      <Select
        ref={ref}
        value={value}
        setValue={(value) => form.setValue(name, value)}
        render={props.render}
      >
        {props.children}
      </Select>
    )
    const field = <FormControl name={name} render={select} />
    return <Ariakit.Role.button {...props} render={field} />
  }
)
